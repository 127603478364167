
$(document).ready(function(){
		$('.price--basic').hover(function(){
			$('.price__plan-item').css('background-color','#ff8e24');
			$('.price__plan-item').css('color','#fff');
		},
		function(){
			$('.price__plan-item').css('background-color','#fff');
			$('.price__plan-item').css('color','#ff8e24');
		});
	});

