(function(){
  var link4 = document.querySelector(".uptocall-mini-phone");
 var popup1 = document.querySelector(".modal-content-phone");
 var close1 = document.querySelector(".modal-content-phone-close");
 // var name1 = popup.querySelector("[name=name]");
 // var tel = popup.querySelector("[name=tel]");
 // var storage = localStorage.getItem("login");

      
      link4.addEventListener("click", function(event) {
        event.preventDefault();
        popup1.classList.add("modal-content-show-phone");
        if (storage) {
          login.value = storage;
          password.focus();
        } else {
          login.focus();
        }
      });

      close1.addEventListener("click", function(event) {
        event.preventDefault();
        popup1.classList.remove("modal-content-show-phone");
        popup1.classList.remove("modal-error");
      });

      form.addEventListener("submit", function(event) {
        if (!name1.value || !tel.value) {
          event.preventDefault();
          popup1.classList.remove("modal-error");
          popup1.offsetWidth = popup1.offsetWidth;
          popup1.classList.add("modal-error");
        } else {
          localStorage.setItem("login", login.value);
        }
      });

      window.addEventListener("keydown", function(event) {
        if (event.keyCode === 27) {
          if (popup1.classList.contains("modal-content-show-phone")) {
            popup1.classList.remove("modal-content-show-phone");
            popup1.classList.remove("modal-error");
          }
        }
      });
}());
