(function(){
 var link1 = document.querySelector(".price__btn--basic");
 var link2 = document.querySelector(".price__btn--standart");
 var link3 = document.querySelector(".price__btn--premium");


 var popup = document.querySelector(".modal-content");
 var close = document.querySelector(".modal-content-close");
 var name = popup.querySelector("[name=name]");
 var mail = popup.querySelector("[name=password]");
 var text = popup.querySelector("[name=text]");
 var storage = localStorage.getItem("login");

      link1.addEventListener("click", function(event) {
        event.preventDefault();
        popup.classList.add("modal-content-show");
        if (storage) {
          login.value = storage;
          password.focus();
        } else {
          login.focus();
        }
      });
      link2.addEventListener("click", function(event) {
        event.preventDefault();
        popup.classList.add("modal-content-show");
        if (storage) {
          login.value = storage;
          password.focus();
        } else {
          login.focus();
        }
      });
      link3.addEventListener("click", function(event) {
        event.preventDefault();
        popup.classList.add("modal-content-show");
        if (storage) {
          login.value = storage;
          password.focus();
        } else {
          login.focus();
        }
      });


      close.addEventListener("click", function(event) {
        event.preventDefault();
        popup.classList.remove("modal-content-show");
        popup.classList.remove("modal-error");
      });

      form.addEventListener("submit", function(event) {
        if (!name.value || !mail.value || !text.value) {
          event.preventDefault();
          popup.classList.remove("modal-error");
          popup.offsetWidth = popup.offsetWidth;
          popup.classList.add("modal-error");
        } else {
          localStorage.setItem("login", login.value);
        }
      });

      window.addEventListener("keydown", function(event) {
        if (event.keyCode === 27) {
          if (popup.classList.contains("modal-content-show")) {
            popup.classList.remove("modal-content-show");
            popup.classList.remove("modal-error");
          }
        }
      });
}());
